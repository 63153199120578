.footer {
	background-color: var(--blue-2);
	color: white;
	padding: 30px 0;
	margin-top: auto;

	@include media-breakpoint-up('lg', $grid-breakpoints) {
		padding: 40px 0;
	}

	&__wrapper {
		margin-left: -($grid-gutter-width/2);
		margin-right: -($grid-gutter-width/2);
	}

	p {
		font-weight: normal;
		margin-top: 0;

		span {
			white-space: nowrap;
		}
	}

	ul {
		display: flex;
		list-style-type: none;
		margin: 0 auto 20px;
		padding: 0;

		@include media-breakpoint-up('md', $grid-breakpoints) {
			margin: 0 0 20px;
		}

		li {
			margin-right: 10px;

			&:last-child {
				margin: 0;
			}

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 30px;
				width: 30px;
				border: 1px solid white;
				border-radius: 50%;
				font-size: 14px;
				color: white;
				text-decoration: none;

				@include media-breakpoint-up('md', $grid-breakpoints) {
					height: 40px;
					width: 40px;
					font-size: 18px;
				}

				&:hover {
					color: var(--blue-2);
					background-color: white;
				}
			}
		}
	}

	&__block {
		width: 100%;
		padding-left: $grid-gutter-width/2;
		padding-right: $grid-gutter-width/2;

		@include media-breakpoint-up('md', $grid-breakpoints) {
			width: 50%;
			max-width: 425px;
		}

		&.logo {
			width: $grid-gutter-width + 100;
			flex-shrink: 0;
			margin-bottom: 20px;

			@include media-breakpoint-up('lg', $grid-breakpoints) {
				width: $grid-gutter-width + 120;
			}
		}
	}

	&__logo {
		display: block;
		max-width: 120px;
		margin: 0 auto;
	}

	&__copy {
		margin-top: 25px;

		p {
			font-size: .7rem;
			font-weight: normal;
			text-align: center;
		}
	}
}