.prize {
	margin-bottom: 30px;

	@include media-breakpoint-up('lg', $grid-breakpoints) {
		margin-bottom: 40px;
	}

	@include media-breakpoint-up('xl', $grid-breakpoints) {
		margin-bottom: 60px;
	}

	@include media-breakpoint-up('xxxl', $grid-breakpoints) {
		margin-bottom: 70px;
	}

	img {
		margin-bottom: 1.5rem;
	}

	h3 {
		font-size: 24px;
		margin-bottom: 1rem;

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			font-size: 32px;
		}

		@include media-breakpoint-up('xl', $grid-breakpoints) {
			font-size: 40px;
		}
	}

	a {
		text-decoration: none;

		h3 {
			color: var(--brown-1);
			transition: color .25s ease-in-out;
		}

		&:hover {

			h3 {
				color: var(--green-1);
			}
		}

		&:active {

			h3 {
				color: var(--brown-1);
			}
		}
	}
}