.content {

	p,
	ul,
	ol {
		margin: 1rem 0;
	}

	p {

		a {
			color: var(--links);
			text-decoration: none;
			border-bottom: 1px solid var(--links);

			&:hover {
				border-color: transparent;
			}
		}
	}

	ul {
		list-style: none;
		padding: 0;

		li {
			position: relative;
			padding-left: 1em;

			&:last-child {
				margin-bottom: 0;
			}

			&:before {
				position: absolute;
				content: '';
				left: .5em;
				top: .675em;
				height: 2px;
				width: 2px;
				border-radius: 50%;
				background: black;
			}
		}
	}

	table {
		width: 100%;

		thead {

			th {
				text-align: left;
				font-weight: 600;
			}
		}

		th,
		td {
			border-bottom: 1px solid rgba(0,0,0,.4);
			padding: .75rem .5em;

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}
	}

	article {
		margin-bottom: 2rem;

		header {
			font-size: 22px;
			font-family: 'Podkova', sans-serif;
			margin: 0;
			font-weight: 800;
			line-height: 1em;
			color: var(--brown-1);

			@include media-breakpoint-up('lg', $grid-breakpoints) {
				font-size: 24px;
			}

			@include media-breakpoint-up('xl', $grid-breakpoints) {
				font-size: 32px;
			}

			@include media-breakpoint-up('xxl', $grid-breakpoints) {
				font-size: 36px;
			}

			@include media-breakpoint-up('xxxl', $grid-breakpoints) {
				font-size: 40px;
			}
		}

		div {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			line-clamp: 3;
			-webkit-line-clamp: 3;
			overflow: hidden;
			margin-bottom: 1em;
		}

		p {

			&:last-of-type {
				margin-bottom: 0
			}
		}

		a {
			display: none;
			font-size: 1em;
			color: black;
			text-decoration: none;
			border-bottom: 1px solid black;

			&:hover {
				color: var(--green-2);
				border-color: var(--green-2);
			}

			&:active {
				color: var(--green-1);
				border-color: var(--green-1);
			}

			&:before {
				content: 'Показать полностью';
			}

			&:after {
				display: none;
				content: 'Скрыть';
			}
		}

		&.opened  {

			div {
				line-clamp: initial;
				-webkit-line-clamp: initial;
			}

			a {

				&:before {
					display: none;
				}

				&:after {
					display: inline;
				}
			}
		}

		&.has-more {

			a {
				display: table;
			}
		}
	}

	.pagination {
		margin: 3em 0;

		div {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
		}

		span,
		a {
			display: block;
			height: 2rem;
			width: 2rem;
			line-height: calc(2rem - 4px);
			text-decoration: none;
			text-align: center;
			color: var(--brown-1);
			border: 2px solid var(--gray-1);
			border-radius: 2px;
			margin-right: 10px;
			background: var(--beige-1);
		}

		span {

			&.current {
				border-color: var(--green-1);
			}
		}

		a {

			&:hover {
				border-color: var(--green-1);
				color: var(--green-1);
			}

			&:active {
				color: var(--brown-1);
			}
		}
	}
}