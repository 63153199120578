.header {
	display: flex;
	align-items: center;
	padding: 10px 0;
	background-color: var(--beige-2);
	position: relative;
	z-index: 20;

	@include media-breakpoint-up('lg', $grid-breakpoints) {
		z-index: 10;
	}

	&__logo {

		a {
			display: block;

			@include media-breakpoint-up('xxl', $grid-breakpoints) {
				max-width: initial !important;
			}
		}

		&-zagorodniy {
			transform: translate(0, -24%);
			max-width: 100px;
			margin-right: 25px;

			@include media-breakpoint-up('xl', $grid-breakpoints) {
				max-width: 120px;
			}
		}

		&-tricolor {
			max-width: 90px;
			transform: translate(0, 16%);

			@include media-breakpoint-up('xl', $grid-breakpoints) {
				max-width: 120px;
			}
		}

		img {
			display: block;
			max-width: 100%;
		}
	}

	&__menu {
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		padding: 10px 20px 20px;
		background-color: var(--beige-2);
		border-bottom: 1px solid var(--brown-3);
		visibility: hidden;
		opacity: 0;
		transition: visibility .4s ease-in-out,
					opacity .4s ease-in-out;

		@include media-breakpoint-up('md', $grid-breakpoints) {
			padding: 0 20px;
		}

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			display: block;
			position: static;
			min-height: initial;
			visibility: visible;
			opacity: 1;
			border: none;
		}

		ul {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			list-style-type: none;
			margin: 0 -10px;
			padding: 0;

			@include media-breakpoint-up('md', $grid-breakpoints) {
				flex-direction: row;
			}

			@include media-breakpoint-up('xl', $grid-breakpoints) {
				margin: 0 -15px;
			}

			@include media-breakpoint-up('xxl', $grid-breakpoints) {
				margin: 0 -20px;
			}

			li {
				padding: 10px 0;

				@include media-breakpoint-up('md', $grid-breakpoints) {
					padding: 20px 10px;
				}

				@include media-breakpoint-up('xl', $grid-breakpoints) {
					padding: 20px 15px;
				}

				@include media-breakpoint-up('xxl', $grid-breakpoints) {
					padding: 30px 20px;
				}

				a {
					color: black;
					font-size: 14px;
					font-weight: 500;
					text-decoration: none;
					border-bottom: 1px solid transparent;

					&:hover {
						color: var(--green-1);
					}

					@include media-breakpoint-up('xl', $grid-breakpoints) {
						font-size: 16px;
					}
				}

				&.active {

					a {
						color: var(--green-1);
						border-color: var(--green-1);
						cursor: default;
						pointer-events: none;
					}
				}
			}
		}
	}

	&.menu-open {

		.header {

			&__menu {
				visibility: visible;
				opacity: 1;
			}
		}
	}
}