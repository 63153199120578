.cards {

	h2 {
		margin-bottom: 1em;
	}

	&__items {
		padding: 0 10px;
		margin-bottom: -20px;
		z-index: 1;

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			padding: 0 5px;
			margin-bottom: -30px;
		}

		@include media-breakpoint-up('xl', $grid-breakpoints) {
			padding: 0;
			margin-bottom: -40px;
		}

		.col {
			display: flex;
			flex: 0 0 100%;
   			width: 100%;
			padding: 0 10px 20px;

			@include media-breakpoint-up('lg', $grid-breakpoints) {
				padding: 0 15px 30px;
			}

			@include media-breakpoint-up('xl', $grid-breakpoints) {
				padding: 0 20px 40px;
			}

			@media (min-width: 500px) {
				flex: 0 0 50%;
	   			width: 50%;
			}

			@include media-breakpoint-up('lg', $grid-breakpoints) {
				flex: 0 0 25%;
	   			width: 25%;
			}
		}

		&-item {
			display: flex;
			flex-direction: column;
			flex: 1;
			color: var(--brown-1);
			background-color: var(--beige-1);
			border: 1px solid var(--brown-3);
			border-radius: 20px;
			text-decoration: none;
			transition: box-shadow .35s ease-in-out;

			figure {
				margin: 0 0 20px;
				border-radius: 20px;
				overflow: hidden;
			}

			& > div,
			& > p {
				padding: 0 20px;
			}

			& > div {
				margin-bottom: 25px;

				@include media-breakpoint-up('lg', $grid-breakpoints) {
					margin-bottom: 35px;
				}

				div {
					font-family: 'Podkova', sans-serif;
					font-size: 18px;
					font-weight: 800;
					line-height: 1em;
					color: var(--brown-1);
					transition: color .35s ease-in-out;
					margin-bottom: 10px;

					@include media-breakpoint-up('xl', $grid-breakpoints) {
						font-size: 20px;
					}

					@include media-breakpoint-up('xxl', $grid-breakpoints) {
						font-size: 25px;
					}
				}

				p {
					margin: 0;
					font-size: .8rem;
				}
			}

			& > p {
				margin: auto 0 20px;
			}
		}

		a.card__items-item {

			&:hover {
				box-shadow: 0px 14px 20px rgba(96, 57, 19, 0.09);

				& > div {

					div {
						color: var(--green-1);
					}
				}
			}

			&:active {
				box-shadow: none;

				& > div {

					div {
						color: var(--brown-1);
					}
				}
			}
		}
	}
}