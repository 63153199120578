.about {
	overflow: hidden;

	h2 {
		margin-bottom: 30px;

		@include media-breakpoint-up('md', $grid-breakpoints) {
			margin-bottom: 40px;
		}

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			margin-bottom: 50px;
		}

		@include media-breakpoint-up('xl', $grid-breakpoints) {
			margin-bottom: 60px;
		}
	}

	p {
		font-size: .625rem;
		font-weight: normal;
		font-style: normal;
	}

	ul {
		list-style: none;
		padding: 0;

		li {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			position: relative;
			font-size: 1rem;
			font-weight: 500;
			margin: 0 0 1rem;
			min-height: 3.2em;

			@include media-breakpoint-up('lg', $grid-breakpoints) {
				margin-left: -65px;
			}

			img {
				align-self: start;
				margin-right: 15px;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__content {
		position: relative;
		margin-bottom: 20px;

		@include media-breakpoint-up('md', $grid-breakpoints) {
			margin-bottom: 25px;
		}

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			padding-left: 80px;
			margin-right: 40px;
			margin-bottom: 30px;
		}

		h3 {
			text-align: left;
		}

		&-title {

			img {
				margin-right: 15px;
			}
		}
		
		&-title,
		&-buttons {
			margin-bottom: 20px;

			@include media-breakpoint-up('lg', $grid-breakpoints) {
				margin-bottom: 30px;
			}
		}

		&-buttons {

			a {
				display: block;
				margin-right: 15px;
			}
		}
	}

	&__devices {
		position: relative;
		margin-bottom: 40px;

		&-wrapper {

			@include media-breakpoint-up('md', $grid-breakpoints) {
				position: absolute;
				left: -160px;
				top: 0;
				width: 9999em;
			}
		}
	}

	&__tricolor {
		margin-bottom: 20px;
		max-width: 64px;

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			position: absolute;
			left: 20px;
			top: 0;
			transform: translate(-50%, 0);
		}

		@include media-breakpoint-up('xl', $grid-breakpoints) {
			left: 0;
			max-width: initial;
		}
	}

	&__google,
	&__apple {
		max-height: 40px;

		@include media-breakpoint-up('sm', $grid-breakpoints) {
			max-height: 50px;
		}
	}

	&__channel {
		position: relative;
		border: 1px solid var(--blue-1);
		border-radius: 30px;
		background-color: var(--beige-1);
		margin-right: 20px;
		padding: 20px 32px 20px 20px;
		box-shadow: var(--shadow-1);

		@include media-breakpoint-up('sm', $grid-breakpoints) {
			padding: 30px 56px 30px 30px;
		}

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			padding: 40px 72px 40px 40px;
			margin-right: 40px;
		}

		div {
			position: absolute;
			left: 100%;
			bottom: 100%;
			height: 4rem;
			width: 4rem;
			border-radius: 50%;
			transform: translate(-50%, 50%);
			box-shadow: var(--shadow-1);

			@include media-breakpoint-up('sm', $grid-breakpoints) {
				height: 5rem;
				width: 5rem;
			}

			img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:before,
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				border: 1px solid var(--blue-1);
				border-radius: 50%;
				animation: scale-with-fadeout 1.6s linear infinite both;
			}
			&:after {
				animation-delay: .8s;
			}
		}

		h5 {
			margin: 0 0 1em;
			font-size: 1rem;
			font-weight: 500;
			color: var(--brown-1);
			line-height: 1.6em;

			i {
				font-style: normal;
				color: var(--green-1);
			}
		}

		p {
			margin: 0;
		}
	}

	&__copyright {
		margin-top: 30px;

		p {
			font-size: .7rem;
			font-weight: normal;
			font-style: normal;

			a {
				color: var(--links);
				text-decoration: none;
				border-bottom: 1px solid var(--links);

				&:hover {
					border-color: transparent;
				}
			}
		}
	}
}