.prizes {
	position: relative;

	&.with-back {
		
		&:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			height: 42%;
			top: 0;
			background-color: var(--brown-2);
		}
	}

	&__top {
		display: flex;
		position: absolute;
		left: 0;
		width: 100%;
		top: calc(-100vw * (150 / 1920));
		z-index: 1;

		img {
			margin: 0 auto;
		}
		
		@include media-breakpoint-up('xxxl', $grid-breakpoints) {
			top: -150px;
		}
	}

	.container {
		position: relative;
		z-index: 2;
	}

	&__bottom {
		position: absolute;
		left: 0;
		width: 100%;
		top: 42%;

		img {
			margin: 0 auto;
		}
	}

	&__buttons {
		margin: 30px 0 -8px;

		@include media-breakpoint-up('sm', $grid-breakpoints) {
			margin-top: 40px;
			margin-right: 20px;
		}

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			margin-top: 50px;
			margin-right: 30px;
		}

		.btn {
			margin: 8px 0;

			&.start {

				@include media-breakpoint-up('md', $grid-breakpoints) {
					margin-right: 30px;
				}
			}
		}
	}
}