.form-group {
	margin-bottom: 10px;

	input[type="text"],
	input[type="tel"],
	input[type="password"],
	input[type="email"],
	div {
		display: block;
		width: 100%;
		border: none;
		background: transparent;
		outline: 0;
		background: var(--beige-1);
		font-size: 1rem;
		border: 1px solid var(--brown-4);
		border-radius: 1.6rem;
		padding: .8rem 1.25rem;
		overflow: hidden;
		transition: color .35s ease-in-out,
					box-shadow .35s ease-in-out,
					border-color .35s ease-in-out,
					background .35s ease-in-out;

		&:hover,
		&:focus,
		&.focus {
			border-color: var(--brown-1);
			color: var(--brown-1);
			box-shadow: var(--shadow-1);
		}
	}

	textarea {
		display: block;
		padding: 0;
		width: 100%;
		border: none;
		background: transparent;
		outline: 0;
		resize: none;
		font-size: inherit;
		line-height: 1.6em;
		
		&::-webkit-scrollbar {
			width: 8px;
			height: 8px;
		}
		
		&::-webkit-scrollbar-thumb {
			background: var(--green-1);
			border-radius: 4px;
		}
		
		&::-webkit-scrollbar-thumb:hover {
			background: var(--green-1);
		}
		
		&::-webkit-scrollbar-track {
			background: var(--beige-1);
			border-radius: 4px;
		}
	}

	&.focus {

		div {
			border-color: var(--brown-1);
			color: var(--brown-1);
			box-shadow: var(--shadow-1);
		}
	}

	p {
		display: none;
		margin: .5rem 0 1rem;
		color: var(--red);
		font-size: .6rem;
		line-height: 1.2em;
		padding: 0 1.25rem;
	}

	&.invalid {

		input[type="text"],
		input[type="tel"],
		input[type="password"],
		input[type="email"],
		div {
			border-color: var(--red) !important;
		}

		p {
			display: block;
		}
	}
}

.form-group.checkbox {
	font-size: .7rem;
	line-height: 1.4em;

	label {
		display: block;
		padding-left: 24px;
		position: relative;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 12px;
			top: .6em;
			transform: translate(-50%, -50%);
			transition: visibility .35s ease-in-out,
						opacity .35s ease-in-out;
		}

		&:before {
			height: 12px;
			width: 12px;
			border: 1px solid var(--brown-4);
			border-radius: 2px;
			transition: border-color .35s ease-in-out;
		}

		&:after {
			visibility: hidden;
			opacity: 0;
			height: 6px;
			width: 6px;
			background: var(--brown-4);
			transition: background .35s ease-in-out;
		}

		a {
			color: var(--brown-4);
			text-decoration: none;
			border-bottom: 1px solid var(--brown-4);

			&:hover {
				border-color: transparent;
			}
		}

		&:hover {
			
			&:before {
				border-color: var(--green-1);
			}
			
			&:after {
				background: var(--green-1);
			}
		}
	}

	input[type="checkbox"] {
		display: none;

		&:checked + label {

			&:after {
				visibility: visible;
				opacity: 1;
			}
		}
	}

	&.invalid {

		label {
			
			&:before {
				border-color: var(--red);
			}
		}
	}
}

.form-divider {
	margin-top: 1rem;

	&.big {
		margin-top: 2rem;
	}
}

#status {
	color: var(--red);
	text-align: center;
}