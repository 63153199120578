.blocker {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: rgba(0,0,0,.8);
	padding: $grid-gutter-width / 2;
	visibility: hidden;
	opacity: 0;
	transition: opacity .4s ease-in-out,
				visibility .4s ease-in-out;
	z-index: 99;
	text-align: center;

	&:before{
		content: "";
		display: inline-block;
		height: 100%;
		vertical-align: middle;
		margin-right: -0.05em;
	}

	@include media-breakpoint-up('md', $grid-breakpoints) {
		padding: $grid-gutter-width;
	}
}

body.show-modal {
	overflow: hidden;
	
	.blocker {
		visibility: visible;
		opacity: 1;
		overflow: auto;
	}
}

.modal {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	width: 95%;
	max-width: 1500px;
	background: var(--beige-1);
	border-radius: 10px;
	overflow: hidden;
	text-align: left;
	padding: 40px 1rem 1rem;

	@include media-breakpoint-up('md', $grid-breakpoints) {
		padding: .5rem;
	}
}
