.quiz {
	position: relative;
	padding: 30px 0;
	overflow: hidden;

	@include media-breakpoint-up('md', $grid-breakpoints) {
		padding: 40px 0;
	}

	@include media-breakpoint-up('lg', $grid-breakpoints) {
		padding: 60px 0;
	}

	&__mobile {
		margin-bottom: 20px;

		@include media-breakpoint-up('md', $grid-breakpoints) {
			position: absolute;
			top: -9999em;
			left: -9999em;
		}
	}

	&__map {
		border-radius: 10px;
		overflow: hidden;
		position: absolute;
		transform: translate(-9999em, -9999em);
		flex-shrink: 0;

		@include media-breakpoint-up('md', $grid-breakpoints) {
			position: relative;
			transform: none;
		}

		&-points {

			a {
				display: block;
				position: absolute;
				height: 5rem;
				width: 5rem;
				border-radius: 50%;
				background-color: var(--beige-1-90);
				border: 4px solid var(--green-1);
				transform: translate(-50%, -50%);
				box-shadow: var(--shadow-1);
				transition: box-shadow .35s ease-in-out;

				@include media-breakpoint-up('md', $grid-breakpoints) {
					height: 2.25rem;
					width: 2.25rem;
					border-width: 2px;
				}

				i {
					position: absolute;
					left: 50%;
					top: 50%;
					height: 1.6rem;
					width: 1.6rem;
					border-radius: 50%;
					background: linear-gradient(180deg, var(--green-2) 0%, var(--green-3) 100%);
					box-shadow: 0px 14px 20px rgba(96, 57, 19, 0.09);
					transform: translate(-50%, -50%);
					transition: transform .35s linear;

					@include media-breakpoint-up('md', $grid-breakpoints) {
						height: .725rem;
						width: .725rem;
					}
				}

				&:before,
				&:after {
					content: '';
					position: absolute;
					left: -4px;
					top: -4px;
					right: -4px;
					bottom: -4px;
					border: 2px solid var(--green-1);
					border-radius: 50%;
					animation: scale-with-fadeout 1.6s linear infinite both;

					@include media-breakpoint-up('md', $grid-breakpoints) {
						left: -2px;
						top: -2px;
						right: -2px;
						bottom: -2px;
						border-width: 1px;
					}
				}
				&:after {
					animation-delay: .8s;
				}

				&:hover {
					box-shadow: var(--shadow-1);

					i {
						transform: translate(-50%, -50%) scale(1.6);
					}
				}

				&:active {
					box-shadow: none;

					i {
						transform: translate(-50%, -50%) scale(1);
					}
				}

				&.answered {
					border-color: var(--gray-1);

					i {
						background: var(--gray-1);
					}

					&:before,
					&:after {
						border-color: var(--gray-1);
					}
				}
			}
		}
	}
}

.question {

	.close {
		display: block;
		position: absolute;
		right: 10px;
		top: 10px;
		height: 30px;
		width: 30px;
		overflow: hidden;
		text-decoration: none;
		font-size: 50px;
		line-height: 30px;
		color: var(--brown-1);
		z-index: 5;
		text-align: center;

		@include media-breakpoint-up('md', $grid-breakpoints) {
			right: 15px;
			top: 15px;
		}
	}

	&__progress {
		position: absolute;
		left: 0;
		top: 0;
		height: 5px;
		background: var(--blue-1);
	}

	&__image {


		&-wrapper {
			position: relative;
			padding-top: calc(370 / 740 * 100%);
			border-radius: 10px;
			// background: white;ы
			overflow: hidden;
			margin-bottom: 1rem;

			@include media-breakpoint-up('sm', $grid-breakpoints) {
				margin-bottom: 2rem;
			}

			@include media-breakpoint-up('md', $grid-breakpoints) {
				margin-bottom: 0;
			}
		}

		&-image {
			display: flex;
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			// padding: 10px;

			img {
				display: block;
				max-height: 100%;
				max-width: 100%;
				object-fit: contain;
				margin: auto;
			}
		}
	}

	&__content,
	&__buttons {
		padding: 0;

		@include media-breakpoint-up('md', $grid-breakpoints) {
			padding: 40px 40px 40px 20px;
		}

		@include media-breakpoint-up('xl', $grid-breakpoints) {
			padding: 50px 50px 50px 30px;
		}
	}

	&__content {
		margin-bottom: 1rem;

		@include media-breakpoint-up('sm', $grid-breakpoints) {
			margin-bottom: 2rem;
		}

		& > p {
			margin: 0 0 1rem;
			font-size: .8em;
		}

		div {
			color: var(--brown-1);

			h3 {
				font-size: 1.5rem;
				text-align: left;
				margin-bottom: 1rem;

				@include media-breakpoint-up('sm', $grid-breakpoints) {
					font-size: 2rem;
				}
			}

			p {
				font-size: .875rem;
				font-weight: normal;

				@include media-breakpoint-up('sm', $grid-breakpoints) {
					font-size: 1rem;
				}
			}
		}
	}

	&__buttons {

		.btn {
			width: 100%;
			margin: 0 auto 10px;

			&:last-of-type {
				margin-bottom: 0;
			}
		
			@include media-breakpoint-up('sm', $grid-breakpoints) {
				margin: 0 10px 0 0;

				&:last-of-type {
					margin-right: 0;
				}
			}
		
			@include media-breakpoint-up('md', $grid-breakpoints) {
				margin: 0 15px 0 0;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}

}