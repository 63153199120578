.main {
	position: relative;

	&__header {
		position: relative;
		background-color: var(--beige-2);
		padding-top: 20px;

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			padding-top: 30px;
		}

		h3 {
			margin-bottom: 1em;
		}

		.d-flex {

			h3 {
				text-align: left;
				margin-right: $grid-gutter-width;
				margin-bottom: 0;
			}
		}

		&-progress {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			list-style-type: none;
			margin: 15px 0 5px;
			padding: 0;

			li {
				margin-right: $grid-gutter-width / 2;

				@include media-breakpoint-up('md', $grid-breakpoints) {
					margin-right: $grid-gutter-width;
				}

				&:last-child {
					margin-right: 0 !important;
				}

				div {
					display: flex;
					align-items: center;
					font-size: .8rem;
					white-space: nowrap;
					color: black;
					opacity: .5;

					span {
						display: none;
						align-items: center;
						justify-content: center;
						height: .8rem;
						width: .8rem;
						border: 1px solid var(--green-3);
						border-radius: 50%;
						background-color: var(--beige-1);
						color: var(--green-3);
						font-size: .725em;
						margin-right: 5px;
					}
				}

				&.active {

					div {
						opacity: 1;
					}
				}

				&.finished {

					div {

						span {
							display: flex;
						}
					}
				}
			}
		}
	}

	&__bottom {
		display: flex;
		align-items: start;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		z-index: -1;

		img {
			margin: 0 auto;
		}
	}
}