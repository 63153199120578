.winners {

	.col {
		flex: 0 0 100%;
		width: 100%;

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			flex: 0 0 50%;
			width: 50%;
		}

		@include media-breakpoint-up('xl', $grid-breakpoints) {
			flex: 0 0 calc(50% - 1rem);
			width: calc(50% - 1rem);
		}
	}

	&__prize {
		margin-bottom: 3rem;

		@include media-breakpoint-up('xl', $grid-breakpoints) {
			margin-bottom: 4rem;
		}


		img {
			margin-bottom: 1.5rem;
			border-radius: 20px;
		}

		h3 {
			text-align: left;
			font-size: 22px;
			margin-bottom: 1rem;

			@include media-breakpoint-up('lg', $grid-breakpoints) {
				font-size: 26px;
			}

			@include media-breakpoint-up('xl', $grid-breakpoints) {
				font-size: 30px;
			}
		}

		table {

			thead {

				th {

					&:last-child {
						text-align: right;
					}
				}
			}

			tbody {

				td {

					&:last-child {
						text-align: right;
					}
				}
			}
		}
	}
}