.result {
	padding-bottom: 0;
	background-color: var(--beige-2);

	h1 {
		font-weight: 800;
		font-size: 50px;
		text-align: center;
		color: var(--brown-5);
		text-shadow: 0px 1px 1px rgba(69, 44, 27, 0.5);
		margin-bottom: 25px;

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			font-size: 60px;
		}

		@include media-breakpoint-up('xl', $grid-breakpoints) {
			font-size: 80px;
		}

		@include media-breakpoint-up('xxl', $grid-breakpoints) {
			font-size: 100px;
		}

		@include media-breakpoint-up('xxxl', $grid-breakpoints) {
			font-size: 120px;
		}
	}

	p {
		text-align: center;
	}

	&__results {
		margin-top: 30px;
		margin-bottom: -40px;

		p {
			margin-bottom: 1rem;
		}

		@include media-breakpoint-up('md', $grid-breakpoints) {
			margin-top: 45px;
		}

		@include media-breakpoint-up('xl', $grid-breakpoints) {
			margin-top: 60px;
		}

		&-item {
			margin-bottom: 40px;

			h4,
			p {
				text-align: left;
			}

			h4 {
				margin-bottom: 1rem;

				span {
					color: var(--green-1);
				}
			}

			& > div {
				position: relative;
				height: 4px;
				background: var(--gray-2);
				margin: 2rem .6rem .5rem;

				&:before,
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					height: 1.2rem;
					width: 1.2rem;
					background: var(--beige-1);
					border: 2px solid var(--gray-2);
					box-sizing: border-box;
					border-radius: 50%;
					transform: translate(0, -50%);
					z-index: 2;
				}
				&:before {
					left: -.6rem;
				}
				&:after {
					right: -.6rem;
				}

				i {
					position: absolute;
					left: 0;
					top: 50%;
					height: 4px;
					background: var(--blue-gradient-1);
					transform: translate(0, -50%);
					z-index: 1;
				}

				span {
					position: absolute;
					left: 0;
					top: 50%;
					height: 4px;
					transform: translate(0, -50%);
					z-index: 3;

					&:before,
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						height: .5rem;
						width: .5rem;
						background: var(--blue-gradient-1);
						border-radius: 50%;
						transform: translate(0, -50%);
					}

					&:before {
						left: -.25rem;
					}

					&:after {
						right: -.25rem;
					}
				}
			}
		}
	}
}