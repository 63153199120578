:root {
	--links: #2D9CDB;

	--beige-1: #FFFCF5;
	--beige-2: #FFF5E0;
	
	--beige-1-90: rgba(255, 251, 241, 0.9);

	--brown-1: #452C1B;
	--brown-2: #F7EBE4;
	--brown-3: #EAB760;
	--brown-4: #807A70;
	--brown-5: #603913;

	--green-1: #29B263;
	--green-2: #3AF087;

	--green-3: #27AE60;

	--blue-1: #70BAFF;
	--blue-2: #10142A;

	--gray-1: #BDBDBD;
	--gray-2: #DDDAD4;

	--red: #EB5757;

	--green-gradient-1: linear-gradient(180deg, #39F086 0%, #27AE60 100%);
	--green-gradient-2: linear-gradient(180deg, #39F086 0%, #27AE60 174.37%);
	
	--blue-gradient-1: linear-gradient(0deg, #4FACFE 0%, #00F2FE 100%);

	--shadow-1: 0px 14px 20px rgba(96, 57, 19, 0.09);
	--shadow-2: 0px 14px 40px rgba(96, 57, 19, 0.19);
}