.intro {
	position: relative;
	background-color: var(--beige-2);
	padding-bottom: 0;
		
	@include media-breakpoint-up('md', $grid-breakpoints) {
		padding-bottom: calc(100vw * (360 / 1920));
	}
		
	@include media-breakpoint-up('xxxl', $grid-breakpoints) {
		padding-bottom: 360px;
	}

	h1 {
		text-align: left;
		margin-bottom: 30px;
	}

	ul {
		list-style-type: none;
		padding-left: 20px;

		@include media-breakpoint-up('sm', $grid-breakpoints) {
			padding: 0;
		}

		li {
			position: relative;
			
			&:before {
				content: '';
				position: absolute;
				left: -20px;
				top: .6125em;
				height: .5rem;
				width: .5rem;
				border-radius: 50%;
				background: linear-gradient(180deg, #FFDFAD -5.74%, #E9B65E 100%);
				transform: translate(0, -50%);
			}
		}
	}

	&__text {
		font-weight: 500;
		margin-bottom: 40px;

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			margin-bottom: 60px;
		}
	}

	&__dates {
		font-size: .8em;
		margin: 0 0 10px;
	}

	&__with-image {
		position: relative;

		img {
			position: absolute;
			left: 0;
			top: 0.5125em;
			transform: translate(-100%, 0);
			z-index: 10;
		}
	}

	&__buttons {
		margin: -8px 0;
		padding-bottom: 15px;

		.btn {
			margin: 8px 0;

			&.start {

				@include media-breakpoint-up('sm', $grid-breakpoints) {
					margin-right: 30px;
				}
			}
		}
	}

	&__house {
		position: relative;
		display: block;
		overflow: hidden;
		z-index: 15;

		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			height: 15vw;
			bottom: 0;
			background-color: var(--brown-2);
			z-index: -1;
		}

		img {
			display: block;
			max-width: 100%;
		}

		@include media-breakpoint-up('md', $grid-breakpoints) {
			position: absolute;
			left: 62%;
			right: 0;
			bottom: 0;
			height: calc(100% + 30px);
			margin-bottom: 0;
			transform: translate(0, 30px);

			&-wrapper {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 9999em;
				max-width: 1015px;
			}

			&:after {
				display: none;
			}

			img {
				display: block;
				height: 100%;
			}
		}

		@include media-breakpoint-up('lg', $grid-breakpoints) {
			left: 48%;
		}

		@include media-breakpoint-up('xl', $grid-breakpoints) {
			transform: none;
		}

		.clouds {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: auto;
			z-index: 2;
		}
	}

	&__bottom {
		display: flex;
		align-items: end;
		position: absolute;
		bottom: 15vw;
		left: 0;
		width: 100%;

		img {
			margin: 0 auto;
		}
		
		@include media-breakpoint-up('md', $grid-breakpoints) {
			bottom: 0;
		}
	}
}