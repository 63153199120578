.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	height: 60px;
	border: none;
	border-radius: 30px;
	width: 100%;
	max-width: 400px;
	padding: 15px 30px;
	font-size: 18px;
	font-weight: 600;
	line-height: 1.2em;
	white-space: nowrap;
	overflow: hidden;
	transition: color .35s ease-in-out,
				box-shadow .35s ease-in-out,
				border-color .35s ease-in-out,
				background .35s ease-in-out;

	&.sm {
		height: 50px;
		padding: 10px 30px;
		border-radius: 25px;
	}

	@include media-breakpoint-up('md', $grid-breakpoints) {
		width: auto;
	}

	@include media-breakpoint-up('xl', $grid-breakpoints) {
		height: 80px;
		padding: 20px 50px;
		border-radius: 40px;
		font-size: 20px;

		&.sm {
			height: 60px;
			padding: 10px 40px;
			border-radius: 30px;
		}
	}

	&.disabled {
		pointer-events: none;
		cursor: default;
	}

	&.large-font {
		font-size: 24px;

		@include media-breakpoint-up('sm', $grid-breakpoints) {
			margin-right: 30px;
		}

		@include media-breakpoint-up('xxl', $grid-breakpoints) {
			font-size: 30px;
		}
	}

	// Gradient-green
	&.btn-1 {
		position: relative;
		color: white;
		background: var(--green-1);
		box-shadow: var(--shadow-1);
		z-index: 1;

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			transition: opacity .35s ease-in-out,
						visibility .35s ease-in-out;
		}
		&:before {
			background: var(--green-gradient-1);
		}
		&:after {
			background: var(--green-gradient-2);
			opacity: 0;
			visibility: hidden;
		}

		&:hover {
			box-shadow: var(--shadow-2);
			
			&:before {
				opacity: 0;
				visibility: hidden;
			}
			
			&:after {
				opacity: 1;
				visibility: visible;
			}
		}

		&:active {
			box-shadow: none;
			
			&:after {
				opacity: 0;
				visibility: hidden;
			}
		}

		&.disabled {
			box-shadow: none;
			background: var(--gray-1);
			
			&:before,
			&:after {
				display: none;
			}
		}

		&.active {
			box-shadow: none;
			background: var(--green-1);
			
			&:after {
				display: block;
			}
		}
	}

	// Border-green
	&.btn-2 {
		border: 2px solid var(--green-1);
		color: black;
		background: white;

		&:hover {
			color: white;
			background: var(--green-2);
			border-color: var(--green-2);
		}

		&:active {
			background: var(--green-1);
			border-color: var(--green-1);
		}

		&.disabled {
			color: var(--gray-1);
			border-color: var(--gray-1);
		}

		&.active {
			color: white;
			background: var(--green-1);
			border-color: var(--green-1);
		}
	}
}

button.btn {
	width: 100%;
	max-width: initial;
}