@import "custom";

@import "~normalize.css/normalize.css";

@import "~hamburgers/_sass/hamburgers/hamburgers.scss";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Grid
@import "~bootstrap/scss/mixins/breakpoints";
@import "~bootstrap/scss/mixins/grid";
@import "~bootstrap/scss/mixins/grid-framework";
@import "~bootstrap/scss/grid";

// Utilites
@import "~bootstrap/scss/utilities/flex";
@import "~bootstrap/scss/utilities/display";


@import "colors";

html {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 1em;
	box-sizing: border-box;

	@include media-breakpoint-up('lg', $grid-breakpoints) {
		font-size: 18px;
	}

	@include media-breakpoint-up('xl', $grid-breakpoints) {
		font-size: 20px;
	}
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background-color: var(--beige-1);
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

* > p {

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

p,
ul {
	line-height: 1.6em;
}

p:empty {
	display: none;
}

a,
button {
	transition: color .25s ease-in-out,
				border-color .25s ease-in-out,
				background-color .25s ease-in-out;
}

.img-responsive {
	display: block;
	max-width: 100%;
}

h1,
h2,
h3,
h4 {
	font-family: 'Podkova', sans-serif;
	margin: 0;
	font-weight: 800;
	line-height: 1em;
	color: var(--brown-1);
}

header {

	h1,
	h2,
	h3,
	h4 {
		text-align: center;
	}
}


h1 {
	font-size: 36px;
	line-height: 1em;

	@include media-breakpoint-up('lg', $grid-breakpoints) {
		font-size: 50px;
	}

	@include media-breakpoint-up('xl', $grid-breakpoints) {
		font-size: 60px;
	}

	@include media-breakpoint-up('xxl', $grid-breakpoints) {
		font-size: 70px;
	}

	@include media-breakpoint-up('xxxl', $grid-breakpoints) {
		font-size: 80px;
	}
}

h2 {
	font-size: 30px;
	line-height: 1.14em;

	@include media-breakpoint-up('lg', $grid-breakpoints) {
		font-size: 40px;
	}

	@include media-breakpoint-up('xl', $grid-breakpoints) {
		font-size: 50px;
	}

	@include media-breakpoint-up('xxl', $grid-breakpoints) {
		font-size: 60px;
	}

	@include media-breakpoint-up('xxxl', $grid-breakpoints) {
		font-size: 70px;
	}
}

h3 {
	font-size: 24px;
	line-height: 1.2em;

	@include media-breakpoint-up('lg', $grid-breakpoints) {
		font-size: 28px;
	}

	@include media-breakpoint-up('xl', $grid-breakpoints) {
		font-size: 36px;
	}

	@include media-breakpoint-up('xxl', $grid-breakpoints) {
		font-size: 42px;
	}

	@include media-breakpoint-up('xxxl', $grid-breakpoints) {
		font-size: 50px;
	}
}

h4 {
	font-size: 18px;
	line-height: 1.12em;

	@include media-breakpoint-up('lg', $grid-breakpoints) {
		font-size: 20px;
	}

	@include media-breakpoint-up('xxl', $grid-breakpoints) {
		font-size: 25px;
	}
}

.block {
	padding: 30px 0;

	@include media-breakpoint-up('md', $grid-breakpoints) {
		padding: 65px 0;
	}

	@include media-breakpoint-up('lg', $grid-breakpoints) {
		padding: 85px 0;
	}

	h2 {
		text-align: center;
	}

	&.beige {
		background-color: var(--beige-2);
	}

	&.pt-0 {
		padding-top: 0 !important;
	}

	&.pb-0 {
		padding-bottom: 0 !important;
	}
}

@-webkit-keyframes scale-with-fadeout {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(1.625);
		opacity: 0;
	}
}


@import "modal";
@import "buttons";
@import "forms";
@import "content";
@import "cards";

@import "header";
@import "main";
@import "footer";

@import "blocks/intro";
@import "blocks/prizes";
@import "blocks/prize";
@import "blocks/about";
@import "blocks/quiz";
@import "blocks/result";
@import "blocks/winners";