// Humburgers
$hamburger-padding-x           : 0;
$hamburger-padding-y           : 0;
$hamburger-layer-width         : 30px;
$hamburger-layer-height        : 3px;
$hamburger-layer-spacing       : 5px;
$hamburger-layer-color         : #000;
$hamburger-layer-border-radius : 0;
$hamburger-hover-opacity       : 1;
$hamburger-types: (elastic);

.hamburger {
	height: 20px;
}

// Bootstrap
$enable-grid-classes: true;

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1400px,
	xxl: 1600px,
	xxxl: 1920px
);

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1300px,
	xxxl: 1520px
);

$grid-columns: 12;
$grid-gutter-width: 40px;
$grid-row-columns: 6;

// Map transitions
$transition-time: .3s;
$transition-delay: .4s;